<template>
  <page-head-wrapper>
    <!-- 操作栏 -->
    <template v-slot:handles-btns>
      <template v-if="!edit">
        <a-button size="small" @click="onEdit" class="mr-16"><i class="e-icon complete"></i>信息完善</a-button>
        <a-button size="small" @click="onMessage" class="mr-16"><i class="e-icon phone"></i>短信提醒</a-button>
        <a-button size="small" @click="onRefresh"><i class="e-icon refresh"></i>刷新</a-button>
      </template>
      <template v-else>
        <a-button type="primary" size="small" class="mr-16" @click="editSubmit"><i class="e-icon save"></i>保存</a-button>
        <a-button size="small" @click="onCancel"><i class="e-icon close"></i>取消</a-button>
      </template>
    </template>

    <a-spin :spinning="loading" v-if="!edit">
      <div class="mp-detail-module">
        <div class="module-top">
          <h3 class="mod-ttile">企业详情</h3>
        </div>
        <div class="module-main">
          <a-row :gutter="8">
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">企业名称：</span>
                <span class="detail-value">杭州亿尚智能有限公司</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">企业类型：</span>
                <span class="detail-value">工商型</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">营业执照：</span>
                <span class="detail-value">4873219847329483214</span>
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="8">
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">营业执照图片：</span>
                <a-button type="link" style="padding: 0" @click="imgVisible = true">点击查看营业执照照片</a-button>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">负责人姓名：</span>
                <span class="detail-value">狄老板</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">负责人身份号码：</span>
                <span class="detail-value">2873987249372498342</span>
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="8">
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">公司地址：</span>
                <span class="detail-value">浙江省杭州市临平区南苑街道新远金座1幢1301室</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">工厂地址：</span>
                <span class="detail-value">浙江省杭州市临平区南苑街道新远金座1幢1301室</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">联系人员：</span>
                <span class="detail-value">陈女士</span>
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="8">
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">联系电话：</span>
                <span class="detail-value">159323133232</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">业务类型：</span>
                <span class="detail-value">-</span>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </a-spin>
    <!-- 查看大图 -->
    <a-modal v-model="imgVisible" title="营业执照照片" centered :width="800" :footer="null">
      <img style="width: 100%;" src="http://5b0988e595225.cdn.sohucs.com/images/20190228/12c89cef9a5c4b72a94969467a6a7c17.jpeg">
    </a-modal>

    <div class="mp-detail-module" v-if="edit">
        <div class="module-top">
          <h3 class="mod-ttile">基础信息</h3>
        </div>
        <div class="module-main">
          <a-form-model
            ref="editForm"
            :model="editForm"
            :rules="editRules"
            layout="vertical"
          > 
            <a-row :gutter="8">
              <a-col :span="12">
                <a-form-model-item label="企业名称">
                  <a-input style="width: 80%" v-model="editForm.companyName" placeholder="请输入企业名称" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="企业类型">
                  <a-radio-group v-model="editForm.companyType">
                    <a-radio value="1">
                      工商型
                    </a-radio>
                    <a-radio value="2">
                      个人型
                    </a-radio>
                  </a-radio-group>
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row :gutter="8">
              <a-col :span="12">
                <a-form-model-item label="营业执照号码">
                  <a-input style="width: 80%" v-model="editForm.companyCode" placeholder="请输入营业执照号码" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="营业执照照片">
                  <span style="cursor:pointer; color: #666">点击查看营业执照照片</span>
                  <a-button type="link">重新上传</a-button>
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row :gutter="8">
              <a-col :span="12">
                <a-form-model-item label="负责人姓名">
                  <a-input style="width: 80%" v-model="editForm.ownerName" placeholder="请输入负责人姓名" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="负责人身份证号">
                  <a-input style="width: 80%" v-model="editForm.ownerCardNumber" placeholder="请输入负责人身份证号" />
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row :gutter="8">
              <a-col :span="12">
                <a-form-model-item label="公司地址">
                  <a-cascader style="width: 30%;" class="address-cascader" :options="options" change-on-select 
                    placeholder="省/市/区(县)"
                  />
                  <a-input style="width: 60%; border-radius: 0 2px 2px  0; border-left-color: #eee;" v-model="editForm.companyAddress" placeholder="请输入公司地址" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="工厂地址（选填）">
                  <a-cascader style="width: 30%;" class="address-cascader" :options="options" change-on-select 
                    placeholder="省/市/区(县)"
                  />
                  <a-input style="width: 60%; border-radius: 0 2px 2px  0; border-left-color: #eee;" v-model="editForm.companyAddress" placeholder="请输入公司地址" />
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row :gutter="8">
              <a-col :span="12">
                <a-form-model-item label="联系人员">
                  <a-input style="width: 80%" v-model="editForm.ownerName" placeholder="请输入联系人员" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="联系电话">
                  <a-input style="width: 80%" v-model="editForm.ownerCardNumber" placeholder="请输入联系电话" />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row :gutter="8">
              <a-col :span="24">
                <a-form-model-item label="业务类型（选填）">
                  <a-checkbox-group v-model="editForm.businessType">
                    <a-checkbox value="1" name="businessType">
                      面辅料供应
                    </a-checkbox>
                    <a-checkbox value="2" name="businessType">
                      产品设计
                    </a-checkbox>
                    <a-checkbox value="3" name="businessType">
                      生产加工
                    </a-checkbox>
                    <a-checkbox value="3" name="businessType">
                      销售
                    </a-checkbox>
                    <a-checkbox value="3" name="businessType">
                      软件服务
                    </a-checkbox>
                    <a-checkbox value="3" name="businessType">
                      硬件服务
                    </a-checkbox>
                  </a-checkbox-group>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </div>
      </div>
    
  </page-head-wrapper>
</template>

<script>
export default {
  name: 'static-data-company-incomplete-detail',
  keep_alive_tab: true,
  data() {
    return {
      // 页面编辑
      edit: false,

      loading: false,
      imgVisible: false,

      editForm: {
        companyName: '',
        companyType: '',
        companyCode: '',
        ownerName: '',
        ownerCardNumber: '',
        companyAddress: '',
        factoryAddress: '',
        companyContact: '',
        companyPhone: '',
        businessType: [],
      },
      editRules: {

      },

      options: [
        {
          value: '浙江',
          label: '浙江',
          children: [
            {
              value: '杭州',
              label: '杭州',
              children: [
                {
                  value: '西湖',
                  label: '西湖',
                },
              ],
            },
          ],
        },
        {
          value: '江苏',
          label: '江苏',
          children: [
            {
              value: '南京',
              label: '南京',
              children: [
                {
                  value: '中华门',
                  label: '中华门',
                },
              ],
            },
          ],
        },
      ],
    }
  },
  methods: {
  
    onEdit() {
      this.edit = true
    },
    onCancel() {
      this.edit = false
    },
    onMessage() {
      this.$message.success('发送短信成功')
    },

    // 刷新操作
    onRefresh() {},

    // 审核表单
    editSubmit() {
      this.$refs.editForm.validate(valid => {
        if (valid) {
          this.$showLoading({
            tip: '正在保存...'
          })
          setTimeout(() => {
            this.$hideLoading()
            this.eidt = false
            this.$success({
              title: '提示',
              centered: true,
              content: '保存成功'
            })

          }, 2000)
        }
      })
    },
  }
}
</script>

<style lang="less">
.address-cascader {
  border-radius: 2px 0 0 2px;
  .ant-input {
    border-right-color: #eee;
    border-radius: 2px 0 0 2px;
  }
}
</style>